import React, { useState, useEffect } from "react";

import { TableRow, Table, TableData, TableHeader } from "./components";
import { useTable, useExpanded, usePagination } from "react-table";
import { motion } from "framer-motion";
import customFetch, { API_URL } from "../utils/fetch";
import { useGroupBy } from "react-table/dist/react-table.development";
import { DateTime } from "luxon";

export function Unit({ unitId }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Scout",
        accessor: (data) =>
          `${data?.subscription?.Scout?.firstName} ${data?.subscription?.Scout?.lastName}`,
      },
      {
        Header: "Name",
        accessor: (data) => {
          if (data?.subscription?.addon?.name) {
            return data.subscription.addon.name;
          } else if (data?.subscription?.subscriptionPlan?.name) {
            return data?.subscription?.subscriptionPlan?.name;
          } else {
            return "Upfront Membership Fee";
          }
        },
        aggregate: "count",
        Aggregated: ({ value }) => `${value} transactions`,
      },
      {
        Header: "Amount",
        accessor: (data) =>
          `$${data.amount
            ? Number.parseFloat(data.amount).toFixed(2)
            : Number.parseFloat(data.upfrontAmount).toFixed(2)
          }`,
        aggregate: (values) => {
          return `${values
            .reduce((sum, cur) => sum + Number.parseFloat(cur.slice(1)), 0.0)
            .toFixed?.(2)}`;
        },
        Aggregated: ({ value }) => `$${Number.parseFloat(value).toFixed(2)} `,
      },
      {
        Header: "Date",
        accessor: (row) => row.updatedAt,
        Cell: (props) => (
          <span>{DateTime.fromISO(props.value).toFormat("DDD")}</span>
        ),
        Aggregated: () => "",
      },
    ],
    []
  );

  const [transactions, setTransactions] = useState([]);
  // const [scouts, setScouts] = useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsExpanded,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: transactions,
      initialState: {
        groupBy: ["Scout"],
        pageIndex: 0,
      },
      autoResetExpanded: false,
    },
    useGroupBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    customFetch(`${API_URL}/units/${unitId}/transaction`).then((data) =>
      setTransactions(data?.transactions)
    );
  }, [unitId]);

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [toggleAllRowsExpanded, transactions]);

  // useEffect(() => {
  //   customFetch(`${API_URL}/units/${unitId}/scouts`).then((data) =>
  //     setScouts(data?.scouts)
  //   );
  // }, [unitId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Unidad {unitId}</h1>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Table>
          <thead>
            <TableRow>
            </TableRow>
          </thead>
          <tbody>

            <TableRow>
            </TableRow>

          </tbody>
        </Table>

        <div className="pagination py-4">
          {/* pagination*/}
        </div>
      </motion.div>
    </motion.div>
  );
}
