import React, { useState } from 'react';

function RolesManagement() {
    const [selectedFeature, setSelectedFeature] = useState('Create New Role');
    const [customColor, setCustomColor] = useState("#111111");


    const handleFeatureSelect = (feature) => {
        setSelectedFeature(feature);
    };

    const rowLabels = {
        activityLog: "Activity Log",
        channels: "Channels",
        favoriteChannels: "Favorite Channels",
    };


    const [permissions, setPermissions] = useState({
        all: { activityLog: false, channels: false, favoriteChannels: false },
        read: { activityLog: false, channels: false, favoriteChannels: false },
        create: { activityLog: false, channels: false, favoriteChannels: false },
        update: { activityLog: false, channels: false, favoriteChannels: false },
        delete: { activityLog: false, channels: false, favoriteChannels: false },
    });

    const checkAndUpdateHeaders = (updatedPermissions) => {
        const allTypes = ['read', 'create', 'update', 'delete'];

        ['activityLog', 'channels', 'favoriteChannels'].forEach(header => {
            const isAllChecked = allTypes.every(type => updatedPermissions[type][header]);
            updatedPermissions['all'][header] = isAllChecked;
        });

        const isAllChecked = allTypes.every(type =>
            ['activityLog', 'channels', 'favoriteChannels'].every(header => updatedPermissions[type][header])
        );
        updatedPermissions['all']['all'] = isAllChecked;

        return updatedPermissions;
    };

    const handleSelectHeader = (type) => {
        setPermissions(prevState => {
            // determinate
            const newState = type === 'all' ? !prevState['all']['activityLog'] : !prevState[type]['activityLog'];

            if (type === 'all') {
                const updatedPermissions = {};
                // loop 
                Object.keys(prevState).forEach(permissionType => {
                    updatedPermissions[permissionType] = {};
                    Object.keys(prevState[permissionType]).forEach(row => {
                        updatedPermissions[permissionType][row] = newState;
                    });
                });
                return checkAndUpdateHeaders(updatedPermissions);
            } else {
                // update the column
                const updatedPermissions = {
                    ...prevState,
                    [type]: Object.keys(prevState[type]).reduce((acc, key) => {
                        acc[key] = newState; // new state for each row in this column
                        return acc;
                    }, {}),
                };

                Object.keys(rowLabels).forEach(row => {
                    // check if every option in the row is selected 
                    const allSelected = ['read', 'create', 'update', 'delete'].every(t => updatedPermissions[t][row]);
                    updatedPermissions['all'][row] = allSelected;
                });

                return checkAndUpdateHeaders(updatedPermissions);
            }
        });
    };

    const handleSelectRow = (type, row) => {
        setPermissions(prevState => {
            if (type === 'all') {
                const newState = !prevState[type][row];
                const updatedPermissions = {};
                Object.keys(prevState).forEach(permissionType => {
                    updatedPermissions[permissionType] = { ...prevState[permissionType], [row]: newState };
                });
                return updatedPermissions;
            } else {
                const updatedPermissions = {
                    ...prevState,
                    [type]: {
                        ...prevState[type],
                        [row]: !prevState[type][row],
                    }
                };
                return checkAndUpdateHeaders(updatedPermissions);
            }
        });
    };



    return (
        <div className="roles-management-container">
            <div className="sidebar">
                <div className="system-features">
                    <div
                        className={`system-feature ${selectedFeature === 'Roles List' ? 'active' : ''}`}
                        onClick={() => handleFeatureSelect('Roles List')}>
                        Roles List
                    </div>
                    <div
                        className={`system-feature ${selectedFeature === 'Create New Role' ? 'active' : ''}`}
                        onClick={() => handleFeatureSelect('Create New Role')}>
                        Create New Role
                    </div>
                    <div
                        className={`system-feature ${selectedFeature === 'System Features' ? 'active' : ''}`}
                        onClick={() => handleFeatureSelect('System Features')}>
                        System Features
                    </div>
                </div>
            </div>

            <div className="role-creation-panel">
                {selectedFeature === 'Roles List' && <div>Content for Activity Log</div>}
                {selectedFeature === 'Create New Role' && (
                    <div className="create-role-content">
                        <div className="header">
                            <h1 className="create-new-role-title">Create New Role</h1>
                            <button className="create-role-btn text-sm font-semibold">Create Role</button>
                        </div>
                        <hr className="divider" />
                        <form className="role-form">
                            <div className="form-row">
                                <div className="form-field">
                                    <label htmlFor="roleName">Name of Role</label>
                                    <input type="text" id="roleName" placeholder='--' />
                                </div>
                                <div className="form-field">
                                    <label htmlFor="customColor">Custom Color</label>
                                    <div className="color-picker">
                                        <input
                                            type="text"
                                            id="customColor"
                                            placeholder="Paste color code or choose"
                                            value={customColor}
                                            onChange={(e) => setCustomColor(e.target.value)}
                                        />
                                        <div className="color-input-wrapper">
                                            <input
                                                type="color"
                                                id="colorSelector"
                                                value={customColor}
                                                onChange={(e) => setCustomColor(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-field">
                                <label htmlFor="description">Description</label>
                                <input type="text" placeholder='--' />
                            </div>
                        </form>

                        <div className="form-field">
                            <label htmlFor="description">Permissions</label>
                            <table className="permissions-table">
                                <tbody>
                                    <tr>
                                        {['all', 'read', 'create', 'update', 'delete'].map(type => (
                                            <td key={type} className="column-header-cell"> {/* Aplica la clase aquí */}
                                                <input
                                                    type="checkbox"
                                                    id={`header-${type}`}
                                                    checked={
                                                        permissions[type].activityLog &&
                                                        permissions[type].channels &&
                                                        permissions[type].favoriteChannels
                                                    }
                                                    onChange={() => handleSelectHeader(type)}
                                                />
                                                <label htmlFor={`header-${type}`}>{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                                            </td>
                                        ))}
                                    </tr>
                                    {Object.keys(rowLabels).map(row => (
                                        <tr key={row}>
                                            {['all', 'read', 'create', 'update', 'delete'].map(type => (
                                                <td key={`${type}-${row}`}>
                                                    <input
                                                        type="checkbox"
                                                        id={`${type}-${row}`}
                                                        checked={permissions[type][row]}
                                                        onChange={() => handleSelectRow(type, row)}
                                                    />
                                                    {type === 'all' && <label htmlFor={`${type}-${row}`}>{rowLabels[row]}</label>}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {selectedFeature === 'System Features' && <div>System Features here </div>}
            </div>
        </div>
    );
}

export default RolesManagement;
