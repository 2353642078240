import React, { useEffect } from "react";
import "./assets/main.css";
import { useRoutes } from "hookrouter";
import Checkout from "./Checkout";
import AdminRouter from "./admin";
import Login from "./login";
import LeaderPortal from "./LeaderPortal";
import LeaderCheckout from "./LeaderCheckout";
import { PanelStyleProvider } from './admin/PanelStyle';


export function Input({ passedRef, hasError, className, ...rest }) {
  return (
    <input
      className={`rounded-lg h-12 p-2 px-3 w-full placeholder-gray-400 ${className} ${hasError ? "" : "mb-4"
        }`}
      ref={passedRef}
      {...rest}
    />
  );
}

let orderId;

function setOrderId(ordId) {
  orderId = ordId;
}

function Success() {
  return (
    <div className="flex items-center justify-center w-full h-screen text-5xl"></div>
  );
}

let routes = {
  "/": () => ({ setOrderId }) => (
    <div className="">Please use the link sent to you by email!</div>
  ),
  "/login": () => () => <Login />,
  "/app*": () => () => <AdminRouter />,
  "/checkout": () => ({ setOrderId }) => <Checkout setOrderId={setOrderId} />,
  "/checkout/:troopNumber": ({ troopNumber }) => ({ setOrderId }) => (
    <Checkout setOrderId={setOrderId} troopNumber={troopNumber} />
  ),
  "/checkout/leader/:troopNumber": ({ troopNumber }) => ({ setOrderId }) => (
    <LeaderCheckout setOrderId={setOrderId} troopNumber={troopNumber} />
  ),
  "/success": () => ({ orderId }) => <Success orderId={orderId} />,
  "/leader/portal": () => () => <LeaderPortal />,
};

function App() {
  const routeResult = useRoutes(routes);

  return (
    <PanelStyleProvider>
      <div className="h-full">
        <div className="flex justify-center w-full h-full mx-auto">
          {routeResult({ orderId, setOrderId }) || <div>Nothing Found</div>}
        </div>
      </div>
    </PanelStyleProvider>
  );
}

export default App;
