import React from 'react';

function Integration() {
    return (
        <div>
            <h2>Integration</h2>
        </div>
    );
}

export default Integration;
