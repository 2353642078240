import React from 'react';

function MoreSettings() {
    return (
        <div>
            <h2> More Settings</h2>
        </div>
    );
}

export default MoreSettings;
