
import React, { useState, useEffect, useRef } from 'react';
import "react-dates/lib/css/_datepicker.css";
import { motion } from "framer-motion";
import { AddnewIcon, MoreIcon, NoDataIcon, FilterIcon } from '../assets/icons/hiking';
import { usePanelStyle } from './PanelStyle';


export function Transactions() {

  const [data, setData] = useState([]);
  const [selectedNonce, setSelectedNonce] = useState(''); //nonce from channel

  const [searchValue, setSearchValue] = useState('');
  const [message, setMessage] = useState(''); // error message

  const [sortedBy, setSortedBy] = useState('latest');
  const [showSortOptions, setShowSortOptions] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(0);

  useEffect(() => {
    const eid = localStorage.getItem('default');
    const type = "activity";
    const filter = "TAM-Events";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    setIsLoading(true);
    setData([]);
    setMessage('');

    let url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&search=' + encodeURIComponent(searchValue) + '&filter=' + filter;

    // add the nonce parameter if NONCE is not empty
    if (selectedNonce) {
      url += '&channel_nonce=' + encodeURIComponent(selectedNonce);
    }

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('error');
        }
        return response.json();
      })
      .then(data => {
        if (data.success && data.data.success && (data.data.count > 0 && data.data.response !== "NO")) {
          let sortedData = data.data.response;
          if (sortedBy === 'latest') {
            sortedData = sortedData.reverse();
          }
          setData(sortedData);
          setMessage('');
        } else {
          setData([]);
          setMessage('No Data');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage('No Data');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedNonce, searchValue, sortedBy, triggerFetch]);


  //sorted by


  const toggleSortOptions = () => {
    setShowSortOptions(!showSortOptions);
  };

  const handleSortChange = (order) => {
    setSortedBy(order);
    setShowSortOptions(false);
  };



  // search an entry
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };


  const [channels, setChannels] = useState([]);
  const [triggerChannelFetch, setTriggerChannelFetch] = useState(0);

  useEffect(() => {
    const eid = localStorage.getItem('default');
    const type = "channel";
    const nonce = localStorage.getItem('nonce')
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&nonce=' + nonce;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.data.success && Array.isArray(data.data.response)) {
          setChannels(data.data.response);
        } else {

          setChannels([]);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setChannels([]);
      });
  }, [triggerChannelFetch]);


  //popup view details of events
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const handleSourceIpClick = (nonce) => {

    const eid = localStorage.getItem('default');
    const type = "activity";
    const filter = "TAM-Events"


    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&search=' + nonce + '&filter=' + filter;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setPopupData(data.data.response[0].tam_event);
        setIsPopupVisible(true);
      })
      .catch(error => {
        console.error('Error fetching data with nonce:', error);
        setIsPopupVisible(false);
      });
  };

  // view details of events
  const Popup = () => {
    const eventDetails = popupData;

    const handleCloseClick = (e) => {
      e.stopPropagation();
      setIsPopupVisible(false);
    };

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <div className="flex justify-between items-start">
            <h2 className="text-lg font-semibold mb-4">View Details:</h2>
            <button className="text-lg font-semibold" onClick={() => setIsPopupVisible(false)}>x</button>
          </div>
          <p>Nonce: {eventDetails.nonce}</p>
          <p>Event Type: {eventDetails.event_type}</p>
          <p>Source IP: {eventDetails.source_ip}</p>
          <p>Referring System: {eventDetails.referring_system}</p>
          <p>Comment: {eventDetails.comment}</p>
          <p>Status: {eventDetails.status}</p>
          <p>Created Date: {eventDetails.created_date}</p>
          <p>Last Action Date: {eventDetails.last_action_date}</p>

          <div>
            <label>Request Payload:</label>
            <textarea
              id="requestPayload"
              className="mt-1 p-2 w-full border rounded-md shadow-sm"
              value={eventDetails.request_payload}
              readOnly
            />
          </div>

          <div className="mt-4">
            <label>Response Payload:</label>
            <textarea
              className="mt-1 p-2 w-full border rounded-md shadow-sm"
              value={eventDetails.response_payload}
              readOnly
            />
          </div>

          <div className="flex justify-end mt-6">
            <button className="py-2 px-4 text-white rounded bg-gray-500 hover:bg-gray-700" onClick={() => setIsPopupVisible(false)}>Close</button>
          </div>

        </div>
      </div>
    );
  };

  const [responseAddEvent, setResponseAddEvent] = useState('');

  //Report Event
  function RequestPopup({ onClose }) {
    const [referringSystem, setReferringSystem] = useState('');
    const [sourceIp, setSourceIp] = useState('');
    const [channelType, setChannelType] = useState('tam_channel');
    const [status, setStatus] = useState('');
    const [comment, setComment] = useState('');

    const [actionDetail, setActionDetail] = useState('');
    const [targetDetail, setTargetDetail] = useState('');

    const addRequest = () => {
      const eid = localStorage.getItem('default');
      const Payload = {

        referring_system: referringSystem,
        source_ip: sourceIp,
        channel_type: channelType,
        status: status,
        event_type: "Event",
        comment: comment,

        secondary_action: actionDetail,
        secondary_target: targetDetail,
        channel_nonce: selectedNonce,
        eid: eid,
        endpoint: 'v1/tam/event/add',
      };

      fetch('/admin/postData.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(Payload),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success === 1) {
            setIsSuccess(true);
            setTriggerFetch(prev => prev + 1);
            setResponseAddEvent(`Event Created Successfully`);
          } else {
            setIsSuccess(false);
            setResponseAddNote(data.response);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };


    const handleCloseEvent = () => {
      setIsRequestPopupVisible(false);
      setResponseAddEvent('');
    };
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center pt-10 z-50">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto">
          <h2 className="text-lg font-semibold mb-4">ADD EVENT</h2>

          <label className="text-sm font-semibold">Referring System</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            value={referringSystem}
            onChange={(e) => setReferringSystem(e.target.value)}
          />

          <label className="text-sm font-semibold">Source</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            value={sourceIp}
            onChange={(e) => setSourceIp(e.target.value)}
          />



          <label className="text-sm font-semibold">Channel Type</label>
          <select
            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
            value={channelType}
            onChange={(e) => setChannelType(e.target.value)}
          >
            <option value="tam_channel">TAM Channel</option>
            <option value="trc_one_channel">TRC ONE Channel</option>
          </select>


          <label className="text-sm font-semibold">State</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />

          <label className="text-sm font-semibold">Secondary Action</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            value={actionDetail}
            onChange={(e) => setActionDetail(e.target.value)}
          />

          <label className="text-sm font-semibold">Secondary Target</label>
          <input
            placeholder="---"
            className="mb-2 w-full input-style"
            type="text"
            value={targetDetail}
            onChange={(e) => setTargetDetail(e.target.value)}
          />
          <label className="text-sm font-semibold">Comment</label>
          <textarea
            className="mb-2 w-full input-style textarea-style"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>

          <div className="flex justify-between items-center mt-6">
            <div className={`flex-1 ${responseAddEvent ? `text-${isSuccess ? 'green' : 'red'}-500` : 'invisible'}`}>
              {responseAddEvent}
            </div>
            <button className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={handleCloseEvent}>Close</button>
            <button className="py-2 px-4 bg-redTRC rounded text-white mr-2" onClick={addRequest}>Submit</button>
          </div>

        </div>
      </div>
    );
  }


  //add Event
  const [responseAddNote, setResponseAddNote] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  function addNote() {
    const eid = localStorage.getItem('default');
    const payload = {
      source_ip: inputValues.source_ip,
      referring_system: inputValues.referring_system,
      comment: inputValues.comment,
      status: inputValues.status,
      channel_nonce: selectedNonce,
      channel_type: inputValues.channel_type,
      event_type: "Note",
      endpoint: 'v1/tam/event/add',
      eid: eid,
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          setIsSuccess(true);
          setTriggerFetch(prev => prev + 1);
          setResponseAddNote(`Note Created Successfully`);
        } else {
          setIsSuccess(false);
          setResponseAddNote(data.response);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };


  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };


  const [showPopup, setShowPopup] = useState(false);

  const [inputValues, setInputValues] = useState({
    source_ip: '',
    referring_system: '',
    comment: '',
    status: '',
  });

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); //10 events per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    if (data.length > 0 && totalPages < currentPage) {
      setCurrentPage(1);
    }
  }, [data.length, totalPages, currentPage]);

  const handlePrevPage = () => setCurrentPage(currentPage - 1);
  const handleNextPage = () => setCurrentPage(currentPage + 1);


  let pageNumbers = [];
  const maxPageNumWindow = 5;
  let startPage = Math.max(currentPage - Math.floor(maxPageNumWindow / 2), 1);
  let endPage = Math.min(startPage + maxPageNumWindow - 1, totalPages);

  if (totalPages <= maxPageNumWindow) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (startPage <= 1) {
      startPage = 1;
      endPage = maxPageNumWindow;
    } else if (endPage === totalPages) {
      startPage = totalPages - (maxPageNumWindow - 1);
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  const [selectedChannel, setSelectedChannel] = useState("TAM-Events");
  const [selectedChannelType, setSelectedChannelType] = useState("");

  function handleChannelClick(nonce, channelName, channelType) {
    setSelectedNonce(nonce);
    setSelectedChannel(channelName);
    setSelectedChannelType(channelType);
  }

  const renderContent = () => {
    return (
      <div className="flex-grow bg-white">
        <div className="p-5">
          <div className="header-tokens flex justify-between">
            <div>
              <h1 className="activity-log-title">{selectedChannel}</h1>
              <p class="italic text-red font-bold">{selectedChannelType}</p>
              {currentItems.length > 0 && (
                <p>
                  Last Entry - {currentItems[0].tam_event.created_date}, From  "{currentItems[0].tam_event.referring_system}"
                </p>
              )}
            </div>

            <div className="buttons-container flex flex-col">
              <button className="add-new-token mb-2" onClick={openPopup}>
                <AddnewIcon className="mr-1" />
                ADD NOTE
              </button>

              {/* <button className="flex items-center justify-center px-4 py-2.5 bg-yellow-500 text-white font-semibold rounded-lg cursor-pointer mb-2" onClick={() => setIsRequestPopupVisible(true)}>
                <AddnewIcon className="mr-1" />
                ADD EVENT
              </button> */}
            </div>
          </div>



          <div className="mt-4 flex justify-start items-center w-full relative">
            <input
              value={searchValue}
              onChange={handleSearchChange}
              className="px-4 py-2 border-gray-400 placeholder-gray-600 text-gray-600 rounded border focus:border-gray-400 focus:ring-0 cursor-text flex-grow"
              type="text"
              placeholder="Search an Entry"
            />
            <button
              onClick={toggleSortOptions}
              className="filter-button-token  ml-2"
            >
              Sorted By
              <span className="ml-2"><FilterIcon /></span>
            </button>
            {showSortOptions && (
              <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button
                    onClick={() => handleSortChange('earliest')}
                    className=" block w-full text-left px-4 py-2 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Earliest
                  </button>

                  <button
                    onClick={() => handleSortChange('latest')}
                    className=" block w-full text-left px-4 py-2 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Latest (Default)
                  </button>

                </div>
              </div>
            )}
          </div>



        </div>
        {/* add NOTE! MODAL */}
        {showPopup && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center pt-10 z-50">
            <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto">

              <h2 className="text-lg font-semibold mb-4">ADD NOTE</h2>

              <label className="text-sm font-semibold">Referring System</label>
              <input
                placeholder="---"
                name="referring_system"
                className="mb-2 w-full input-style"
                type="text"
                value={inputValues.referring_system}
                onChange={handleInputChange}
              />

              <label className="text-sm font-semibold">Source</label>
              <input
                placeholder="---"
                name="source_ip"
                className="mb-2 w-full input-style"
                type="text"
                value={inputValues.source_ip}
                onChange={handleInputChange}
              />



              <label className="text-sm font-semibold">Channel Type</label>
              <input
                placeholder="---"
                name="channel_type"
                className="mb-2 w-full input-style"
                type="text"
                value={inputValues.channel_type}
                onChange={handleInputChange}
              />

              <label className="text-sm font-semibold">Status</label>
              <input
                name="status"
                placeholder="---"
                className="mb-2 w-full input-style"
                type="text"
                value={inputValues.status}
                onChange={handleInputChange}
              />


              <label className="text-sm font-semibold">Comment</label>
              <textarea
                name="comment"
                className="mb-2 w-full input-style textarea-style"
                value={inputValues.comment}
                onChange={handleInputChange}
              ></textarea>

              <div className="flex justify-between items-center mt-6">
                <div className={`flex-1 ${responseAddNote ? `text-${isSuccess ? 'green' : 'red'}-500` : 'invisible'}`}>
                  {responseAddNote}
                </div>
                <div>
                  <button className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={closePopup} >Close</button>
                  <button className="py-2 px-4 bg-redTRC rounded text-white mr-2" onClick={addNote}>Submit</button>
                </div>
              </div>

            </div>
          </div>
        )}
        {/* Add EVENT */}
        {isRequestPopupVisible && <RequestPopup />}
        <div>
          <div>

            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="font-semibold text-gray-400 text-left border-b border-gray-300 px-4 py-2 w-1/4">DATE & TIME</th>
                  <th className="font-semibold text-gray-400 text-left border-b border-gray-300 px-4 py-2 w-1/4">Referring System</th>
                  <th className="font-semibold text-gray-400 text-left border-b border-gray-300 px-4 py-2 w-1/6">Type</th>
                  <th className="font-semibold text-gray-400 text-left border-b border-gray-300 px-4 py-2 w-5/12 break-all break-words">MESSAGE</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item.id}>
                    <td className="py-2 text-sm font-medium bg-gray-50 px-4 w-1/4">{item.tam_event.created_date}</td>
                    <td className="py-2 text-sm font-medium bg-gray-50 px-4 w-1/4">{item.tam_event.referring_system}</td>
                    <td className="py-2 text-sm font-medium bg-gray-50 px-4 text-blue-500 hover:underline cursor-pointer hover:text-blue-700 w-1/6" onClick={() => handleSourceIpClick(item.tam_event.nonce)}>{item.tam_event.event_type}</td>
                    <td className="py-2 text-sm font-medium bg-gray-50 px-4 w-5/12">{item.tam_event.comment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {message && (
              <div className="flex flex-col items-center justify-center h-64">
                <NoDataIcon />
                <div className="text-lg font-semibold text-gray-600">{message}</div>
              </div>
            )}
            {isLoading && (
              <div className="flex flex-col items-center justify-center h-64">
                <div>Loading...</div>
              </div>
            )}
            {isPopupVisible && <Popup />}
            {/* pagination */}
            {data.length > 0 && (
              <div className="flex justify-center items-center space-x-1 my-8">
                <button
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                  className="px-2 py-1 text-sm rounded text-white bg-red-500 hover:bg-red-700 disabled:bg-gray-400"
                >
                  &laquo; First
                </button>

                <button
                  onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-3 py-1 text-sm rounded text-white bg-red-500 hover:bg-red-700 disabled:bg-gray-400"
                >
                  &lt;
                </button>

                {pageNumbers.map(number => (
                  <button
                    key={number}
                    onClick={() => setCurrentPage(number)}
                    className={`px-2 py-1 text-sm rounded ${currentPage === number ? 'bg-red-700 text-white' : 'bg-white text-red-500'} hover:bg-red-500 hover:text-white`}
                  >
                    {number}
                  </button>
                ))}

                <button
                  onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 text-sm rounded text-white bg-red-500 hover:bg-red-700 disabled:bg-gray-400"
                >
                  &gt;
                </button>

                <button
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                  className="px-2 py-1 text-sm rounded text-white bg-red-500 hover:bg-red-700 disabled:bg-gray-400"
                >
                  Last &raquo;
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  };

  // toggle (full view)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (toggleRef.current && !toggleRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMenuOpen]);


  // (full view ) pannel
  const { panelStyle, setPanelStyle } = usePanelStyle();

  const handleChangeStyleClick = () => {
    if (panelStyle.isFullView) {
      setPanelStyle({
        ...panelStyle,
        showText: true,
        imageRotation: 0,
        panelWidth: 'w-56',
        imageWidth: 'w-3/5',
        imageMarginBottom: 'mb-4',
        contentMarginLeft: '224px',
        isFullView: false,
      });
    } else {
      setPanelStyle({
        ...panelStyle,
        showText: false,
        imageRotation: 270,
        panelWidth: 'w-20',
        imageWidth: 'w-full',
        imageMarginBottom: 'mb-10',
        contentMarginLeft: '75px',
        isFullView: true,
      });
    }
    setIsMenuOpen(false);
  };

  const buttonText = panelStyle.isFullView ? "Normal View" : "Full View";


  // add channel (popup)
  const openModal = () => setIsModalOpen(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [channelName, setChannelName] = useState('');
  const [channelType, setChannelType] = useState('');
  const [channelTags, setChannelTags] = useState('');
  const [channelShortDescription, setChannelShortDescription] = useState('');
  const [channelLongDescription, setChannelLongDescription] = useState('');


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [responseAddChannel, setResponseAddChannel] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const eid = localStorage.getItem('default');

    const payload = {
      channel_type: channelType,
      eid: eid,
      endpoint: 'v1/channel/add',
      long_description: channelLongDescription,
      name: channelName,
      short_description: channelShortDescription,
      tags: channelTags,
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success === 1) {
          setIsSuccess(true);
          setTriggerChannelFetch(prev => prev + 1);
          setResponseAddChannel(`Channel Created Successfully`);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }



  //toggle for channels (delet, edit, suspend)
  const [visibleChannelMenu, setVisibleChannelMenu] = useState(null);

  const toggleChannel = (channelId) => {
    setVisibleChannelMenu((prevVisibleChannelMenu) => (
      prevVisibleChannelMenu === channelId ? null : channelId
    ));
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (!e.target.closest(".menu-container")) {
        setVisibleChannelMenu(null);
      }
    };

    document.addEventListener("mousedown", closeMenu);
    return () => document.removeEventListener("mousedown", closeMenu);
  }, []);

  // delete channel
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedChannelForDelete, setSelectedChannelForDelete] = useState(null);

  const handleDeleteClick = (channel) => {
    setSelectedChannelForDelete(channel);
    setShowDeletePopup(true);
  };

  const closeDeletePopup = () => {
    setShowDeletePopup(false);
    setSelectedChannelForDelete(null);
  };

  const confirmDelete = () => {
    if (selectedChannelForDelete) {
      deleteChannel(selectedChannelForDelete.channel.nonce);
      closeDeletePopup();
    }
  };

  const deleteChannel = (channelNonce) => {
    const eid = localStorage.getItem('defalut');
    const payload = {
      eid,
      nonce: channelNonce,
      endpoint: "v1/channel/delete"
    };

    fetch('/admin/postData.php', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success === 1) {
          setTriggerChannelFetch(prev => prev + 1);
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  };

  //edit channel
  const handleEditClick = (channelNonce) => {
    const eid = localStorage.getItem('default');
    const type = "channel";
    const nonce = localStorage.getItem('nonce')

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&id=' + channelNonce + '&nonce=' + nonce;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setEditChannelData(data.data.response[0].channel);
          setIsEditChannelModalVisible(true);
        } else {
          console.error('Error al obtener datos para edición');
        }
      })

      .catch(error => {
        console.error('Error de conexión:', error);
      });
  };

  const [isEditChannelModalVisible, setIsEditChannelModalVisible] = useState(false);
  const [editChannelData, setEditChannelData] = useState(null);

  const EditChannelModal = ({ onClose, channel }) => {

    const { name, tags, short_description, long_description, channel_type, nonce } = channel;

    const [channelName, setChannelName] = useState(name);
    const [channelType, setChannelType] = useState(channel_type);
    const [channelTags, setChannelTags] = useState(tags);
    const [channelShortDescription, setChannelShortDescription] = useState(short_description);
    const [channelLongDescription, setChannelLongDescription] = useState(long_description);

    const [channelNonce, setChannelNonce] = useState(nonce);

    const handleUpdate = () => {
      const eid = localStorage.getItem('default')
      const payload = {
        nonce: channelNonce,
        name: channelName,
        channel_type: channelType,
        tags: channelTags,
        short_description: channelShortDescription,
        long_description: channelLongDescription,
        endpoint: 'v1/channel/update',
        eid: eid,
      };

      fetch('/admin/postData.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            window.location.reload();
          }
        })
        .catch(error => {
          console.error('Error sending the payload:', error);
        });
    };


    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
        <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
          <h2 className="text-lg font-semibold mb-4">Edit "{channelShortDescription}" Channel</h2>

          <div className="flex flex-wrap -mx-3 mb-6">

            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="text-sm font-semibold">Name</label>
              <input
                className="w-full input-style"
                id="channel-name"
                type="text"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
              />
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="text-sm font-semibold">Channel Type</label>
              <input
                className="w-full input-style"
                id="channel-name"
                type="text"
                value={channelType}
                onChange={(e) => setChannelType(e.target.value)}
              />
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="text-sm font-semibold">Tags</label>
              <input
                className="w-full input-style"
                id="channel-name"
                type="text"
                value={channelTags}
                onChange={(e) => setChannelTags(e.target.value)}
              />
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="text-sm font-semibold">Short Description</label>
              <input
                className="w-full input-style"
                id="channel-name"
                type="text"
                value={channelShortDescription}
                onChange={(e) => setChannelShortDescription(e.target.value)}
              />
            </div>

            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="text-sm font-semibold">Long Description</label>
              <textarea
                className="mb-2 w-full input-style textarea-style"
                id="channel-name"
                type="text"
                value={channelLongDescription}
                onChange={(e) => setChannelLongDescription(e.target.value)}
              ></textarea>
            </div>
          </div>



          <div className="flex justify-end">
            <button className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={onClose}>Close</button>
            <button className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={handleUpdate}>Update</button>
          </div>

        </div>
      </div>
    );
  };


  //suspend Channel
  const [showSuspendPopup, setShowSuspendPopup] = useState(false);
  const [selectedChannelForSuspend, setSelectedChannelForSuspend] = useState(null);

  const handleSuspendClick = (channel) => {
    setSelectedChannelForSuspend(channel);
    setShowSuspendPopup(true);
  };

  const closeSuspendPopup = () => {
    setShowSuspendPopup(false);
    setSelectedChannelForSuspend(null);
  };

  const confirmSuspend = () => {
    if (selectedChannelForSuspend) {
      suspendChannel(selectedChannelForSuspend.channel.nonce);
      closeSuspendPopup();
    }
  };

  const suspendChannel = (channelNonce) => {

    const eid = localStorage.getItem('default');
    const payload = {
      eid,
      nonce: channelNonce,
      endpoint: "v1/channel/change"
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.success === 1) {
          setTriggerChannelFetch(prev => prev + 1);
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  };
  const [isRequestPopupVisible, setIsRequestPopupVisible] = useState(false);

  const handleClose = () => {
    setIsRequestPopupVisible(false);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="main-container">
        <div className="flex justify-start m-1">
          <div class="flex min-h-screen ">
            <div class="w-[250px] p-2.5">
              <div className="flex justify-between items-center pb-2">
                <h2 className="channels-title">Channels</h2>
                <div className="relative" ref={toggleRef}>
                  <div onClick={toggleMenu} className="cursor-pointer">
                    <MoreIcon />
                  </div>
                  {isMenuOpen && (
                    <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md z-50">
                      <ul>
                        <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={handleChangeStyleClick}>{buttonText}</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search Channels"
                  className="w-full h-8 bg-transparent border border-gray-400 placeholder-gray-600 text-gray-600 py-2 pl-4 pr-10 rounded-md text-sm focus:border-gray-400 focus:ring-0 cursor-text"
                />
                <div>
                  <button
                    className="w-full mt-2 bg-blue-500 text-white py-2 rounded-md text-sm"
                    onClick={openModal}
                  >
                    Add Channel
                  </button>

                  {/* Popup to add channel */}
                  {isModalOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
                      <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-lg">
                        <div className="flex justify-between items-start">
                          <h2 className="text-lg font-semibold mb-4">Add Channel:</h2>
                          <button onClick={closeModal}>X</button>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <label className="text-sm font-semibold">Name</label>
                          <input
                            type="text"
                            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                            value={channelName}
                            onChange={(e) => setChannelName(e.target.value)}
                          />

                          <label className="text-sm font-semibold">Channel Type</label>
                          <select
                            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                            value={channelType}
                            onChange={(e) => setChannelType(e.target.value)}
                          >
                            <option value="channel">Channel</option>
                            <option value="tam_channel">TAM Channel</option>
                            <option value="trc_one_channel">TRC One Channel</option>
                          </select>

                          <label className="text-sm font-semibold">Tags</label>
                          <input
                            value={channelTags}
                            onChange={(e) => setChannelTags(e.target.value)}
                            type="text"
                            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                          />
                          <label className="text-sm font-semibold">Short Description</label>
                          <input
                            value={channelShortDescription}
                            onChange={(e) => setChannelShortDescription(e.target.value)}
                            type="text"
                            className="w-full mb-4 p-2 border border-gray-300 rounded-md"
                          />
                          <label className="text-sm font-semibold">Long Description</label>
                          <textarea
                            value={channelLongDescription}
                            onChange={(e) => setChannelLongDescription(e.target.value)}
                            className="mb-2 w-full input-style textarea-style"
                          ></textarea>


                          <div className="flex justify-between items-center mt-6">
                            <div className={`flex-1 ${responseAddChannel ? `text-${isSuccess ? 'green' : 'red'}-500` : 'invisible'}`}>
                              {responseAddChannel}
                            </div>
                            <div>
                              <button type="button" className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={closeModal}>Close</button>
                              <button
                                type="submit"
                                className="py-2 px-4 bg-redTRC rounded text-white mr-2"
                              >
                                Submit
                              </button>
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <ul className="pt-5">
                <li
                  className={`flex justify-between items-center pt-3 pb-3 text-sm font-semibold cursor-pointer rounded-md ${selectedChannel === "TAM-Events" ? "bg-gray-200" : "hover:bg-gray-200"
                    }`}
                  onClick={() => handleChannelClick('', 'TAM-Events')}>
                  <span className="px-2"># TAM-Events (ALL)</span> <span className="px-2 pr-2">
                  </span>
                </li>
                {channels.map(channel => (
                  <li
                    key={channel.id}
                    className={`flex justify-between items-center pt-3 pb-3 text-sm font-semibold cursor-pointer rounded-md ${selectedChannel === channel.channel.short_description ? "bg-gray-200" : "hover:bg-gray-200"}`}
                    onClick={() => handleChannelClick(channel.channel.nonce, channel.channel.short_description, channel.channel.channel_type)}
                  >
                    <span className="px-2"># {channel.channel.short_description}</span>
                    <span className="px-2 pr-2 relative">
                      <div className="relative z-10" onClick={(e) => {
                        e.stopPropagation();
                        toggleChannel(channel.id);
                      }}>
                        <MoreIcon />
                      </div>
                      {visibleChannelMenu === channel.id && (
                        <div className="absolute left-full top-0 mt-2 w-48 bg-white shadow-md rounded-md z-10 menu-container">
                          <ul>
                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(channel);
                                setVisibleChannelMenu(null);
                              }} x
                            >Delete</li>


                            <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer border-b last:border-b-0"
                              onClick={() => {
                                setVisibleChannelMenu(null);
                                handleEditClick(channel.channel.nonce);
                              }}>
                              Edit
                            </li>


                            <li
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSuspendClick(channel);
                              }}
                            >
                              {channel.channel.suspended_date ? 'Activate' : 'Suspend'}
                            </li>
                          </ul>
                        </div>
                      )}

                      {/* delete channel popup */}
                      {showDeletePopup && selectedChannelForDelete && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
                          <div className="bg-white p-5 rounded-lg shadow-lg text-center">
                            <h2 className="text-xl font-bold mb-4">Delete Channel</h2>
                            <p className="flex justify-center gap-4 mt-4">
                              You are about to delete "{selectedChannelForDelete.channel.short_description}" channel, are you sure?
                            </p>
                            <div className="flex justify-center gap-4 mt-4">
                              <button
                                className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
                                onClick={closeDeletePopup}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={confirmDelete}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* Edit channel popup */}
                      {isEditChannelModalVisible && (
                        <EditChannelModal
                          onClose={() => setIsEditChannelModalVisible(false)}
                          channel={editChannelData}
                        />
                      )}
                      {/* suspend channel popup */}
                      {showSuspendPopup && selectedChannelForSuspend && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
                          <div className="bg-white p-5 rounded-lg shadow-lg text-center">
                            <h2 className="text-xl font-bold mb-4">Change Channel Status</h2>
                            <p className="flex justify-center gap-4 mt-4">
                              You are about to change the status of "{selectedChannelForSuspend.channel.short_description}" channel, are you sure?
                            </p>
                            <div className="flex justify-center gap-4 mt-4">
                              <button
                                className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
                                onClick={closeSuspendPopup}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={confirmSuspend}
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </span>
                  </li>
                ))}
              </ul>

            </div>
          </div>
        </div>
        {renderContent()}
      </div>
    </motion.div >
  );

}