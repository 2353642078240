import React, { useEffect, useState } from "react";
import { TableRow, Table, TableData, TableHeader } from "./admin/components";
import { useTable, useExpanded, usePagination } from "react-table";
import { motion } from "framer-motion";
import customFetch, { API_URL, BASE_API_URL, webTokenKey } from "./utils/fetch";
import { useGroupBy } from "react-table/dist/react-table.development";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { X } from "heroicons-react";
import { Input } from "./App";

export function Unit({ unit, transactions }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Scout",
        accessor: (data) =>
          `${data?.subscription?.Scout?.firstName} ${data?.subscription?.Scout?.lastName}`,
      },
      {
        Header: "Name",
        accessor: (data) => {
          if (data?.subscription?.addon?.name) {
            return data.subscription.addon.name;
          } else if (data?.subscription?.subscriptionPlan?.name) {
            return data?.subscription?.subscriptionPlan?.name;
          } else {
            return "Upfront Membership Fee";
          }
        },
        aggregate: "count",
        Aggregated: ({ value }) => `${value} transactions`,
      },
      {
        Header: "Amount",
        accessor: (data) =>
          `$${data.amount
            ? Number.parseFloat(data.amount).toFixed(2)
            : Number.parseFloat(data.upfrontAmount).toFixed(2)
          }`,
        aggregate: (values) => {
          return `${values
            .reduce((sum, cur) => sum + Number.parseFloat(cur.slice(1)), 0.0)
            .toFixed?.(2)}`;
        },
        Aggregated: ({ value }) => `$${Number.parseFloat(value).toFixed(2)} `,
      },
      {
        Header: "Date",
        accessor: (row) => row.updatedAt,
        Cell: (props) => (
          <span>{DateTime.fromISO(props.value).toFormat("DDD")}</span>
        ),
        Aggregated: () => "",
      },
    ],
    []
  );
  // const [scouts, setScouts] = useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsExpanded,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: transactions,
      initialState: {
        groupBy: ["Scout"],
        pageIndex: 0,
      },
      autoResetExpanded: false,
    },
    useGroupBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [toggleAllRowsExpanded, transactions]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Unit {unit.unitId}</h1>
          <span>Transactions and scouts in this unit</span>
        </div>
      </div>
      {transactions.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <TableRow notHoverable {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableHeader>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  // Use a React.Fragment here so the table markup is still valid
                  <React.Fragment {...row.getRowProps()}>
                    <TableRow>
                      {row.cells.map((cell) => {
                        return (
                          <TableData {...cell.getCellProps()}>
                            {cell.isAggregated
                              ? cell.render("Aggregated")
                              : cell.isPlaceholder
                                ? undefined
                                : cell.render("Cell")}
                          </TableData>
                        );
                      })}
                    </TableRow>
                    {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <div className="py-4 pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}

function ErrorText({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="pl-2 mb-3 text-sm text-red-400"
    >
      {children}
    </motion.div>
  );
}

function Register({ setUserToken, setShowRegisterForm }) {
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState(false);
  const [registering, setRegistering] = useState(false);

  function onSubmit({ loginEmail: email, loginPassword: password, unitId }) {
    setRegistering(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LciX-IZAAAAAEfUCb3RxuM9CIqA4U3xeSB8MOpj", {
          action: "submit",
        })
        .then(function (token) {
          console.log(token);
          fetch(`${BASE_API_URL}/auth/create`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email,
              password,
              unitId,
              token,
            }),
          }).then(async (resp) => {
            setRegistering(false);
            const json = await resp.json();
            if (resp.status === 400) {
              return setError(json.message);
            }
            localStorage.setItem(webTokenKey, json.jwtToken);
            setUserToken(json.jwtToken);
            setShowRegisterForm(false);
          });
        });
    });
  }

  return (
    <div className="fixed top-0 left-0 z-30 flex items-center justify-center w-screen h-screen bg-black bg-opacity-75">
      <div className="w-full max-w-screen-sm p-8 bg-yellow-200 rounded">
        <div className="flex justify-end w-full pb-4">
          <X
            className="cursor-pointer"
            onClick={() => setShowRegisterForm(false)}
          />
        </div>
        {Boolean(error) && (
          <div className="w-full p-2 mb-4 border border-red-600 border-solid rounded">
            {error}
          </div>
        )}
        <h3 className="pb-4 text-2xl font-medium">Register</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Input
              name="loginEmail"
              placeholder="Email Address"
              hasError={errors.loginEmail}
              passedRef={register({
                required: {
                  value: true,
                  message: <ErrorText>Email address is required</ErrorText>,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: <ErrorText>Invalid email address</ErrorText>,
                },
              })}
            />
            {errors.loginEmail && errors.loginEmail.message}
          </div>
          <div className="">
            <Input
              name="loginPassword"
              type="password"
              placeholder="Password"
              hasError={errors.loginPassword}
              passedRef={register({
                required: true,
              })}
            />
            {errors.loginPassword && (
              <ErrorText>Password is required</ErrorText>
            )}
          </div>
          <div>
            <Input
              name="unitId"
              type="number"
              placeholder="Unit ID"
              passedRef={register({
                required: true,
              })}
            />
            {errors.unitId && <ErrorText>Unit Number is required</ErrorText>}
          </div>
          <button
            type="submit"
            disabled={registering}
            className={`p-2 mr-2 rounded-lg text-center text-supernova bg-darkfly ${registering ? "opacity-50 animate-pulse disabled" : ""
              }`}
          >
            {registering ? "Registering..." : "Register"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default function LeaderPortal() {
  const [transactionsByUnit, setTransactionsByUnit] = useState([]);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem(webTokenKey));

  useEffect(() => {
    customFetch(`${API_URL}/leaders/units/transactions`).then((data) => {
      setTransactionsByUnit(data);
    });
  }, []);

  return (
    <div
      className="flex flex-col items-center w-full"
      style={{ maxWidth: "1000px" }}
    >
      {showRegisterForm && (
        <Register
          setShowRegisterForm={setShowRegisterForm}
          setUserToken={setUserToken}
        />
      )}
      {transactionsByUnit?.units?.length === 0 && (
        <>
          {Boolean(userToken) && (
            <div className="pt-4">
              Sorry, you do not have any units assigned to you!{" "}
              <button
                className="text-blue-500 underline"
                onClick={() => {
                  window.history.back();
                }}
              >
                Go back to checkout
              </button>
              . If you are a leader and need assigned a unit, please email{" "}
              <a
                className="text-blue-500 underline"
                href="hoosierquestions@scouting.org"
              >
                hoosierquestions@scouting.org
              </a>
            </div>
          )}

          {!Boolean(userToken) && (
            <div className="self-start pt-2">
              <button
                className="text-blue-500 underline"
                onClick={() => {
                  setShowRegisterForm(true);
                }}
              >
                Register User Only Account
              </button>
            </div>
          )}
        </>
      )}
      {transactionsByUnit?.units?.map?.((unit) => {
        return (
          <div className="flex flex-col w-full pb-8">
            <Unit
              transactions={transactionsByUnit.unitTransactions[unit.id]}
              unit={unit}
            />
          </div>
        );
      })}
    </div>
  );
}
