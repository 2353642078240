import React, { createContext, useContext, useState } from 'react';

const PanelStyleContext = createContext();

export const PanelStyleProvider = ({ children }) => {
    const [panelStyle, setPanelStyle] = useState({
        showText: true,
        imageRotation: 0,
        panelWidth: 'w-56',
        imageWidth: 'w-3/5',
        imageMarginBottom: 'mb-4',
        contentMarginLeft: '224px',
        isFullView: false,
    });

    return (
        <PanelStyleContext.Provider value={{ panelStyle, setPanelStyle }}>
            {children}
        </PanelStyleContext.Provider>
    );
};
export const usePanelStyle = () => useContext(PanelStyleContext);
