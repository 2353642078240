import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { motion } from 'framer-motion';


import GeneralSettings from './settings/GeneralSettings';
import ChannelsManagement from './settings/ChannelsManagement';
import RolesManagement from './settings/Roles.Management';
import UsersSettings from './settings/UsersSettings';
import Integration from './settings/integration';
import ApiSettigns from './settings/API';
import MoreSettings from './settings/more';


export function Payers() {
  const [editorHtml, setEditorHtml] = useState('');
  const [activeSection, setActiveSection] = useState('General Settings');



  function handleChange(html) {
    setEditorHtml(html);
  }

  function submitText() {

    const payload = {
      eid: 'vjksjkjvpeopeopxnn',
      endpoint: 'v1/wysiwyg/save',
      wysiwyg_type: 'settings',
      wysiwyg_text: editorHtml
    };

    fetch('/admin/postData.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log("Text updated successfully");
        } else {
          console.log("Error updating text");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  useEffect(() => {
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const url = protocolpluspath() + 'admin/getData.php?type=wysiwyg';

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.data && data.data.success && Array.isArray(data.data.response)) {
          const filteredData = data.data.response.filter(item => item.id === 0);
          if (filteredData.length > 0) {
            setEditorHtml(filteredData[0].wysiwyg.wysiwyg_text);
          }
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }, []);

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };




  const linkStyle = (section) => {
    return section === activeSection
      ? { borderColor: '#EC2842', color: '#142830' }
      : { borderColor: 'transparent', color: 'gray' };
  };





  const renderContent = () => {
    switch (activeSection) {
      case 'General Settings':
        return <GeneralSettings />;
      case 'Channels Management':
        return <ChannelsManagement />;
      case 'Roles Management':
        return <RolesManagement />;
      case 'Users Settings':
        return <UsersSettings />;
      case 'Integration':
        return <Integration />;
      case 'API':
        return <ApiSettigns />;
      case 'More':
        return <MoreSettings />;
      default:
        return null;
    }
  };


  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <motion.div animate className="flex justify-between py-6">
        <div className="flex-parent flex justify-between px-3">
          <h1 className="settings-title">Settings</h1>
        </div>
      </motion.div>

      <div>
        <nav className="border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="-mb-px flex space-x-8">
              {['General Settings', 'Channels Management', 'Roles Management', 'Users Settings', 'Integration', 'API', 'More'].map((section) => (
                <a
                  key={section}
                  href="#"
                  className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm nav-link ${section === activeSection ? 'active' : ''}`}
                  style={linkStyle(section)}
                  onClick={() => handleSectionClick(section)}
                  aria-current={section === activeSection ? 'page' : undefined}
                >
                  {section}
                </a>
              ))}
            </div>
          </div>
        </nav>
        <div className="section-content">
          {renderContent()}
        </div>
      </div>
    </motion.div>
  );




}
