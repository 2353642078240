import React, { useState, useEffect } from "react";

import { TableRow, Table, TableData, TableHeader } from "./components";
import {
  useTable,
  useExpanded,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { ChevronRight, ChevronDown, UserAdd } from "heroicons-react";
import { motion } from "framer-motion";
import customFetch, { API_URL } from "../utils/fetch";
import { toast } from "react-toastify";
import { usePopper } from "react-popper";
import "@popperjs/core";
import { useForm } from "react-hook-form";
import { navigate } from "hookrouter";

async function updateToLeader(user, unitNos) {
  const resp = await customFetch(`${API_URL}/admin/userToLeader/${user?.id}`, {
    method: "POST",
    body: JSON.stringify({
      unitNos,
    }),
  });
  if (resp.connected) {
    const connectedList = resp.connected.map((conn) => conn.unitId);
    toast.success(
      `${user.email} connected to Units ${connectedList.join(", ")}`,
      {
        autoClose: 5000,
      }
    );
  } else {
    toast.error(`${user.email} failed to connect to any units`, {
      autoClose: 5000,
    });
  }
}

function ErrorText({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="text-red-400 text-sm pl-2 mb-3 flex-wrap w-48"
    >
      {children}
    </motion.div>
  );
}

function UpgradeToLeaderButton({ user }) {
  const { handleSubmit, register, errors } = useForm();
  const [showPopup, setShowPopup] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left",
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  function onSubmit(values) {
    const { unitNos } = values;
    const parsedNos = unitNos.split(",");
    updateToLeader(user, parsedNos);
    setShowPopup(false);
  }

  return (
    <div className="flex">
      <div
        className="p-4 hover:bg-gray-300"
        onClick={(e) => {
          e.preventDefault();
          setShowPopup(!showPopup);
        }}
      >
        <UserAdd
          type="button"
          className="cursor-pointer"
          ref={setReferenceElement}
        />
      </div>

      {showPopup && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={setPopperElement}
          style={styles.popper}
          className="bg-white shadow-lg shadow shadow-xs rounded-lg mb-4 flex flex-col"
          {...attributes.popper}
        >
          <div className="p-4 flex flex-col">
            <label className="text-sm pb-2" htmlFor="unitIds">
              Unit Ids for {user?.email}
            </label>
            <input
              name="unitNos"
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[0-9]*(,[0-9]*)*$/i,
                  message: (
                    <ErrorText>
                      You need to specify unit IDs in a comma separated list
                      with no spaces. <br />
                      E.G. 123,1234,12345
                    </ErrorText>
                  ),
                },
              })}
              aria-label="unitIds"
              className="p-2 border border-gray-200 border-rounded"
              placeholder="123479,1234789,432123"
            />
            {errors.unitNos && errors.unitNos.message}
          </div>
          <button
            type="submit"
            className="px-4 py-2 font-medium text-white bg-supernova rounded-lg"
          >
            Upgrade To Leader
          </button>
          <div className="arrow" ref={setArrowElement} style={styles.arrow} />
        </form>
      )}
    </div>
  );
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  return (
    <div className="flex items-center p-4">
      <div>Search: </div>
      <input
        className="ml-2 p-4 w-full"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </div>
  );
}

export function Users() {
  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) =>
          row.canExpand ? (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ChevronDown /> : <ChevronRight />}
            </span>
          ) : null,
      },
      {
        Header: "Email",
        accessor: (row) => row.email,
        Cell: ({ row }) => {
          return (
            <div
              className="p-4 hover:bg-gray-300"
              onClick={() => {
                navigate(`user/${row.original.id}`);
              }}
            >
              {row.original.email}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          return <UpgradeToLeaderButton user={row?.original} />;
        },
      },
    ],
    []
  );

  const [users, setUsers] = useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    customFetch(`${API_URL}/admin/users`).then(({ users }) => {
      setUsers(users);
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div animate className="flex justify-between py-6">
        <div>
          <h1 className="text-2xl font-medium">Users</h1>
          <span>List of Users in the system</span>
        </div>
      </motion.div>
      {users.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <TableRow notHoverable {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableHeader {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableHeader>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "left",
                  }}
                >
                  <GlobalFilter
                    key="globalfilter"
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
              {page &&
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow>
                      {row.cells.map((cell) => {
                        return (
                          <TableData {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </TableData>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </tbody>
          </Table>
          <motion.div animate className="pagination pt-4">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
}