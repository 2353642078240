import merge from "lodash.merge";
import { navigate } from "hookrouter";

export const isDev = window.location.href.includes("localhost");

export const BASE_API_URL = isDev
  ? "http://localhost:5000"
  : "https://hoosiers.scoutpay.cc";

export const API_URL = isDev
  ? "http://localhost:5000/v1"
  : "https://hoosiers.scoutpay.cc/v1";

export const webTokenKey = "login-token";
const search = new URL(window.location.href).search;
const params = new URLSearchParams(search);

export default async function customFetch(
  url,
  options,
  navigateToLoginOnFail = true
) {
  const webToken = params.has("token")
    ? params.get("token")
    : localStorage.getItem(webTokenKey);
  const defaultOptions = {
    headers: {
      Authorization: `Bearer ${webToken}`,
      "Content-Type": "application/json",
    },
  };

  const res = await fetch(url, merge(defaultOptions, options));
  if ((res.status === 401 || res.status === 403) && navigateToLoginOnFail) {
    navigate("/login", false, { navTo: window.location.pathname });
  } else {
    if (res.headers.get("Content-Type") === "blob") {
      const blob = await res.blob();
      const URL = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = URL;
      a.download = "scoutpay-report.csv";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    } else {
      const data = await res.json();
      data._status = res.status;
      return data;
    }
  }
}

function clearCookies() {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .trim()
      .split("=")[0]
      .concat("=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/");
  });
}

function clearLocalStorage() {
  localStorage.clear();
}
export function logout() {
  clearCookies();
  clearLocalStorage();
  navigate("/login");
}
