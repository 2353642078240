import React from 'react';

function ApiSettigns() {
    return (
        <div>
            <h2> here will be  all the APIs</h2>
        </div>
    );
}

export default ApiSettigns;
