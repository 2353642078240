import React from "react";
import { Input } from "../App";
import { NavItem } from "./components";
import { useRoutes } from "hookrouter";
import { Troops } from "./troops";
import { Transactions } from "./transactions";
import { Payers } from "./payers";
import { Users } from "./users";
import { AnimatePresence, motion } from "framer-motion";
import {
  Logout,
  CreditCard,
  ClipboardList,
  Users as UsersIcon,
  UserGroup,
} from "heroicons-react";
import { logout } from "../utils/fetch";
import Plans from "./plans";
import { IconEnroll, IconSetting, IconOverview, IconClock } from "../assets/icons/hiking";
import { Unit } from "./unit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payer from "./payer";
import User from "./user";
import { usePanelStyle } from './PanelStyle';
const routes = {
  "/": () => <Troops />,
  "/overview": () => <Troops />,
  "/unit/:unitId": ({ unitId }) => <Unit unitId={unitId} />,
  "/tam-events": () => <Transactions />,
  "/settings": () => <Payers />,
  "/payer/:payerId": ({ payerId }) => <Payer payerId={payerId} />,
  "/enroll": () => <Plans />,
  "/users": () => <Users />,
  "/user/:userId": ({ userId }) => <User userId={userId} />,
};


export default function Main() {
  const routeResult = useRoutes(routes);

  const { panelStyle } = usePanelStyle();
  const imageStyle = {
    transform: `rotate(${panelStyle.imageRotation}deg)`,
  };

  const textClass = panelStyle.showText ? '' : 'hidden';
  const panelClass = `${panelStyle.panelWidth} h-full text-white rounded-lg bg-firefly`;
  const imageClass = `${panelStyle.imageWidth} ${panelStyle.imageMarginBottom}`;



  return (
    <div className="flex w-full h-screen px-4 py-4 mx-auto">
      <div className="fixed h-full pr-10 pb-6">
        <div className={`flex flex-col justify-between ${panelClass}`}>
          <div className="flex flex-col items-center p-2 pt-6 pb-6">
            <img className={imageClass} src={`${process.env.PUBLIC_URL}/TRC_4r-logo_white.webp`} style={imageStyle} />
            <p className="text-xs italic text-red">{localStorage.getItem('username')} </p>
            <div className={`px-1 py-0.5 bg-red-600 text-sm text-white font-semibold cursor-default ${textClass}`}>
              Activity Monitor
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-center mb-60">
            <motion.ul className="flex flex-col">
              <NavItem linkTo="/app/overview" selected={routeResult.type === Troops}>
                <IconOverview className="-ml-5 justify-center mr-2" /><span className={textClass}>Overview</span>
              </NavItem>

              <NavItem linkTo="/app/tam-events" selected={routeResult.type === Transactions}>
                <IconClock className="-ml-5 mr-2" /><span className={textClass}>Activity Log</span>
              </NavItem>

              <NavItem linkTo="/app/enroll" selected={routeResult.type === Plans}>
                <IconEnroll className="-ml-5 mr-2" /><span className={textClass}>User Management</span>
              </NavItem>

              {/* <NavItem linkTo="/app/settings" selected={routeResult.type === Payers}>
                <IconSetting className="-ml-5 mr-2" /><span className={textClass}>Settings</span>
              </NavItem> */}

            </motion.ul>
          </div>

          <div className="p-6 text-center">
            <div onClick={logout} className="flex justify-center cursor-pointer">
              <Logout className="pr-2" /><span className={textClass}>Logout</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-4 text-gray-800" style={{ marginLeft: panelStyle.contentMarginLeft, paddingTop: "0" }}>
        <AnimatePresence exitBeforeEnter>
          {routeResult || <div>Nothing Found</div>}
        </AnimatePresence>
      </div>
    </div>
  );

}




