import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
export function Troops() {
  const [ipAccessData, setIpAccessData] = useState({});


  useEffect(() => {
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const type = "wysiwyg";
    const url = protocolpluspath() + 'admin/getData.php?type=' + type;

    fetch(url)
      .then(response => {
        console.log('Response received');
        if (!response.ok) {
          throw new Error('The network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data:', data);
        if (data && data.data && data.data.success && Array.isArray(data.data.response)) {
          const overviewData = data.data.response.find(item => item.wysiwyg && item.wysiwyg.wysiwyg_type === 'overview');
          if (overviewData) {
            const wysiwygData = {
              text: overviewData.wysiwyg.wysiwyg_text,
              type: overviewData.wysiwyg.wysiwyg_type
            };
            setIpAccessData(wysiwygData);
          }
        } else {
          throw new Error('No data returned or the operation was unsuccessful');
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      });
  }, []);




  function InfoBox({ title, number, icon }) {
    return (
      <div className="box-content relative p-4">
        <h2 className="absolute top-4 left-4">{title}</h2>
        {icon && <div className="absolute top-4 right-4">{icon}</div>}
        <div className="box-overview-number absolute bottom-4 left-4 text-xl">{number}</div>
      </div>
    );
  }

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex-parent flex justify-between px-3 ">
        <div>
          <div className="flex-parent flex justify-between px-3 ">
            <h1 className="overview-title">Welcome back,  {localStorage.getItem('username')}!</h1>
            {/* <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span> */}
          </div>
          <div className="flex justify-between mt-4 py-2">
            <InfoBox title="Events: Last Hour" number="07" className="ml-1 mr-1" />
            <InfoBox title="Issues" number="03" className="ml-1 mr-1" />
            <InfoBox title="Total Channels" number="12" className="ml-1" />
          </div>
          <div className="text-overview-container">
            {ipAccessData.text && (
              <div className="unstyled-content">
                <div dangerouslySetInnerHTML={{ __html: decodeHtml(ipAccessData.text) }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );

}


