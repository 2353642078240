import React from 'react';


function ChannelsManagement() {
    return (
        <div>
            <h2>Channels Management</h2>
        </div>
    );
}

export default ChannelsManagement;