import React from 'react';

function UsersSettings() {
    return (
        <div>
            <h2> User Settings</h2>
        </div>
    );
}

export default UsersSettings;
