import React, { Fragment } from "react";
import { A } from "hookrouter";
import { motion } from "framer-motion";
import { Transition, Menu } from "@headlessui/react";

export function Table({ children, ...rest }) {
  return (
    <motion.table
      animate
      transition={{
        type: "spring",
        mass: 0.2,
        tension: 182,
      }}
      initial={false}
      {...rest}
      className="w-full bg-white rounded-lg"
    >
      {children}
    </motion.table>
  );
}

export function TableRow({ children, notHoverable, ...rest }) {
  return (
    <motion.tr
      animate
      transition={{
        type: "spring",
        mass: 0.2,
        tension: 182,
      }}
      {...rest}
      className={`${!notHoverable && "hover:bg-antiflashwhite cursor-pointer"}`}
    >
      {children}
    </motion.tr>
  );
}

export function TableHeader({ children, ...rest }) {
  return (
    <th
      {...rest}
      className="p-4 font-medium text-left border-b-2 border-pampas"
    >
      {children}
    </th>
  );
}

export function TableData({ children, ...rest }) {
  return (
    <td {...rest} className="p-4">
      {children}
    </td>
  );
}

export function AnimatedTableData({ children, ...rest }) {
  return (
    <motion.td
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "100%" }}
      exit={{ opacity: 0, height: 0 }}
      {...rest}
    >
      {children}
    </motion.td>
  );
}

export function NavItem({ children, selected, linkTo = "" }) {
  return (
    <motion.li animate className={`py-1.5 flex relative ${selected && "text-redshade text-shadow-inset"}`}>
      <motion.div
        animate
        className={`absolute left-0 top-0 bottom-0 w-1 ${selected && "bg-redshade"} rounded-r`}
      />
      <A href={linkTo} className="pl-12 text-base font-semibold flex items-center relative z-10">
        {
          React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                fillColor: selected ? "#EC2842" : "white",
                strokeColor: selected ? "#EC2842" : "white"
              });
            }
            return child;
          })
        }
      </A>
    </motion.li>
  );
}


export function PopConfirmButton({
  buttonText,
  disabled,
  onCancel,
  onConfirm,
}) {
  return (
    <Menu className="relative">
      {({ open }) => (
        <>
          <div className={"relative"}>
            <Menu.Button
              disabled={disabled}
              className={`${disabled
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-red-200 hover:border-red-500"
                } inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-gray-700 ${open ? "bg-red-200" : "bg-white"
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {buttonText}
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white shadow rounded-lg p-4 z-10">
                <div className={"pb-2"}>Are you sure?</div>

                <div className={`flex justify-between w-full pt-4`}>
                  <Menu.Item>
                    <button
                      className={`mr-2 inline-flex items-center px-4 py-2 border border-red-300 bg-white rounded-md shadow-sm text-sm font-medium text-gray-700
                     hover:bg-red-200 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() => {
                        onConfirm?.();
                      }}
                    >
                      Yes
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      className={`inline-flex items-center px-4 py-2 border border-gray-300 bg-white rounded-md shadow-sm text-sm font-medium text-gray-700
                     hover:bg-gray-200 hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() => {
                        onCancel?.();
                      }}
                    >
                      No
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
}

export function PopConfirmLink({ buttonText, disabled, onCancel, onConfirm }) {
  return (
    <Menu className="relative">
      {({ open }) => (
        <>
          <div className={"relative"}>
            <Menu.Button
              disabled={disabled}
              className={`${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                } font-medium text-indigo-600 hover:text-indigo-500 ${open ? "text-indigo-300" : ""
                } `}
            >
              {buttonText}
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="absolute left-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white shadow rounded-lg p-4 z-10">
                <div className={"pb-2"}>Are you sure?</div>

                <div className={`flex justify-between w-full pt-4`}>
                  <Menu.Item>
                    <button
                      className={`mr-2 inline-flex items-center px-4 py-2 border border-red-300 bg-white rounded-md shadow-sm text-sm font-medium text-gray-700
                     hover:bg-red-200 hover:border-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() => {
                        onConfirm?.();
                      }}
                    >
                      Yes
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      className={`inline-flex items-center px-4 py-2 border border-gray-300 bg-white rounded-md shadow-sm text-sm font-medium text-gray-700
                     hover:bg-gray-200 hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      onClick={() => {
                        onCancel?.();
                      }}
                    >
                      No
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
}